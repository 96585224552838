<script lang="ts">
  import { onMount } from "svelte";

  let radio: string;

  onMount(() => {
    radio = sessionStorage.getItem("color-mode") || "auto";
  })

  function setTheme(e: Event) {
    console.log(radio);
    document.documentElement.setAttribute("data-color-mode", radio);
    sessionStorage.setItem("color-mode", radio)
  }

</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<div id="theme-toggle-radios" on:change={setTheme}>
  <input
    type="radio"
    id="theme-toggle-light"
    name="theme-toggle-radio"
    value="light"
    bind:group={radio}
  />
  <label id="theme-toggle-light-label" for="theme-toggle-light" class="option">🔆</label>
  <input
    type="radio"
    id="theme-toggle-auto"
    name="theme-toggle-radio"
    value="auto"
    bind:group={radio}
  />
  <label id="theme-toggle-auto-label" for="theme-toggle-auto" class="option"
    >🔘</label
  >
  <input
    type="radio"
    id="theme-toggle-dark"
    name="theme-toggle-radio"
    value="dark"
    bind:group={radio}
  />
  <label id="theme-toggle-dark-label" for="theme-toggle-dark" class="option"
    >🌙</label
  >
  <div id="toggle"/>
</div>

<style lang="scss">
  #theme-toggle-radios {
    width: 98px;
    height: 30px;
    position: relative;
    border-radius: 2rem;
    background: var(--surface3);
    padding-left: 2px;

    #toggle {
      width: 30px;
      height: 30px;
      position: absolute;
      left: var(--toggle-position);
      top: 0;
      border-radius: 50%;
      box-sizing: border-box;
      border: 0;
      background: var(--accent);
      box-shadow: none;
      transition: all 0.2s ease-in;
      opacity: 1;
    }

    label {
      cursor: pointer;
      position: absolute;
      margin: 0;
      padding: 0;
      width: 25px;
      z-index: 1;
      display: inline-block;
      text-align: center;
      line-height: -18px;
      font-size: 18px;
      color: white;
    }

    input {
      position: absolute;
      left: 0;
      margin: 0;
      padding: 0;
      opacity: 0;
    }
  }

  #theme-toggle-light,
  #theme-toggle-light-label {
    left: 0;

    &:checked ~ #toggle {
      --toggle-position: 0;
    }
  }

  #theme-toggle-auto,
  #theme-toggle-auto-label {
    left: 35%;

    &:checked ~ #toggle {
      --toggle-position: 32px;
    }
  }

  #theme-toggle-dark,
  #theme-toggle-dark-label {
    left: 69%;

    &:checked ~ #toggle {
      --toggle-position: 70%;
    }
  }
</style>
